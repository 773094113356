import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },

    {
      path: '/account',
      name: 'account',
      component: () => import('../../src/views/AccountView.vue'),
    },

    {
      path: '/account/inloggen',
      name: 'account-login',
      component: () => import('../../src/views/AccountLogin.vue'),
    },

    {
      path: '/aanmelden',
      name: 'signup',
      props: true,
      component: () => import('../../src/views/SignUp.vue'),
    },

    {
      path: '/aanmelden/verkooppunt',
      name: 'new-store',
      component: () => import('../../src/views/NewStore.vue'),
    },

    {
      path: '/shop',
      name: 'new-order',
      component: () => import('../../src/views/Shop.vue'),
    },

    {
      path: '/shop/store',
      name: 'new-store-order',
      component: () => import('../../src/views/StoreShop.vue'),
    },

    {
      path: '/orders/:uuid',
      name: 'order-details',
      props: true,
      component: () => import('../../src/views/Order.vue'),
    },
  ],
})

export default router

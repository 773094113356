export default {
  errors: {
    people: {
      savePerson: {
        generic: 'Wir konnten Ihre Daten nicht speichern.',
        noDonationAmount:
          'Sie haben einen ungültigen Spendenbetrag eingegeben.',
      },
    },
    orders: {
      createFailure: 'Wir konnten Ihre Bestellung nicht erstellen.',
      unknownUser:
        'Sie versuchen, eine Bestellung für einen unbekannten Benutzer zu erstellen. Bitte melden Sie sich erneut an und versuchen Sie es erneut.',
    },
  },
  account: {
    add_store: 'Neuen Laden hinzufügen',
    complete_registration:
      'Füllen Sie Ihre persönlichen Daten aus, um die Anmeldung abzuschließen.',
    log_out: 'Abmelden',
    stores: 'Läden',
    welcome: 'Willkommen',
  },
  address: {
    country: 'Land',
    postal_code: 'Postleitzahl',
    house_number: 'Hausnummer',
    house_number_suffix: 'Zusatz',
    street: 'Straße',
    locality: 'Wohnort',
  },
  donation_payment: {
    thanks: 'Vielen Dank für Ihre Spende.',
    do_first_payment:
      'Sie können Ihre Spende jetzt abschließen. Möchten Sie jedes Jahr als Erster die neue Flagge automatisch nach Hause erhalten? Wählen Sie dann die jährliche Spende mit Lastschrift. Wir werden von dem Konto abbuchen, mit dem Sie jetzt bezahlen.',
    optionally_once:
      'Wenn Sie Marrekrite nicht zur automatischen Lastschrift autorisieren möchten, wählen Sie: Einmalig spenden.',
    do_pay: 'Jährliche Spende mit Lastschrift',
    do_pay_once: 'Einmalig spenden',
    reload: 'Zahlung überprüfen',
    already_paid: 'Bereits bezahlt?',
  },
  donor_account: {
    payment_failed:
      'Wir konnten keinen Zahlungslink für Sie erstellen. Versuchen Sie es später erneut.',
    paid_notice: 'Wir haben Ihre Spende erfolgreich verarbeitet.',
    set_donation: 'Spende einrichten',
    saved: 'Einstellungen gespeichert',
    max_one_product:
      'Sie können maximal 1 Produkt wählen.<br>In der Webshop können Sie zusätzlich bestellen.',
    product_explanation:
      'Wählen Sie das Produkt, das Sie bei Ihrer Spende erhalten möchten.',
    total_amount: 'Gesamtbetrag: { amount }',
    donation_details:
      'Sie haben sich für <strong>{ product }</strong> entschieden, mit einer Spende von <strong>{ amount }</strong>.',
  },
  generic: {
    amount: 'Menge',
    back: 'Zurück',
    confirm: 'Sind Sie sicher?',
    fail_message: 'Etwas ist schief gelaufen.',
    log_in: 'Einloggen',
    minlength: 'Mindestens { length } Zeichen',
    or: 'oder',
    remove: 'Entfernen',
    save: 'Speichern',
    total: 'Gesamt',
  },
  home: {
    sign_up: 'Anmelden',
    account: 'Konto',
    new_order: 'Neue Bestellung',
  },
  login_form: {
    insert_digits: 'Überprüfung Ihrer E-Mail-Adresse',
    button: 'Anmelden',
    confirm_digits: 'Bestätigen Sie den Überprüfungscode',
    invalid_digits: 'Falscher Überprüfungscode',
    digits: 'Überprüfungscode',
    success:
      'Bitte geben Sie den 6-stelligen Überprüfungscode ein, den wir Ihnen per E-Mail gesendet haben.',

    terms:
      'Mit dem Senden Ihrer E-Mail-Adresse stimmen Sie unserer <a href="https://marrekrite.frl/privacybeleid" class="underline" target="_blank">Datenschutzrichtlinie</a> zu.',
  },
  new_person: {
    button: 'Anmelden',
  },
  new_store: {
    success: 'Laden angemeldet',
    title: 'Laden anmelden',
    button: 'Anmelden',
  },
  order_details: {
    product: 'Produkt',
  },
  order_form: {
    button: 'Bestellen',
    personal_order: 'Persönliche Bestellung',
    store_order: 'Geschäftsbestellung',
    no_lines: 'Es wurden noch keine Produkte hinzugefügt.',
    add_line: 'Produkt hinzufügen',
    total: 'Gesamtbetrag: {amount}',
  },
  people: {
    email: 'E-Mail-Adresse',
    first_name: 'Vorname',
    last_name: 'Nachname',
    phone_number: 'Telefonnummer',
    min_donation_amount: 'Die Spende muss mindestens {amount} betragen',
    donation_amount: 'Spendenbetrag',
    donation_product: 'Produkt',
    kinds: {
      donor: 'Spender',
      store: 'Laden',
    },
  },
  shop: {
    back_to_account: 'Zurück zu Ihrem Konto',
    button: 'Bestellung aufgeben',
    cart: 'Einkaufswagen',
    check_again: 'Erneut überprüfen',
    check_input:
      'Wir konnten das Formular leider nicht verarbeiten. Bitte überprüfen Sie die Eingabe.',
    complete_payments:
      'Bevor Sie eine neue Bestellung aufgeben können, müssen Sie zunächst alle bestehenden Bestellungen begleichen.',
    edit_cart: 'Einkaufswagen bearbeiten',
    enter_personal_details:
      'Geben Sie Ihre persönlichen Informationen ein, um die Bestellung abzuschließen. Im nächsten Schritt können Sie die Bestellung überprüfen.',
    expect_shipping: 'Es können noch Versandkosten anfallen.',
    log_in_first:
      'Um eine Bestellung aufzugeben, benötigen wir Ihre E-Mail-Adresse. Geben Sie diese unten ein und klicken Sie auf den Link, den wir Ihnen senden.',
    next: 'Weiter',
    not_paid: 'Wir haben die Zahlung noch nicht registriert ({ state }).',
    optional_donation: 'Freiwillige Spende',
    paid: 'Ihre Bestellung wurde erfolgreich bezahlt.',
    product_not_available: 'Nicht verfügbar',
    shipping_costs: 'Versandkosten',
    shopping_for_store: 'Sie bestellen für <strong>{ name }</strong>',
    success: 'Wir haben Ihre Bestellung in gutem Zustand erhalten.',
    success_redirect:
      'Wir haben Ihre Bestellung in gutem Zustand erhalten, Sie werden weitergeleitet.',
    thanks: 'Vielen Dank für Ihre Bestellung',
    title: 'Webshop',
    total_amount: 'Gesamtbetrag: { amount }',
  },
  sign_up: {
    title: 'Anmelden als',
    welcome:
      'Willkommen bei Recreatieschap Marrekrite, schön, dass Sie Spender werden möchten. Dafür durchlaufen Sie die folgenden Schritte.',
    steps: {
      1: 'Geben Sie Ihre E-Mail-Adresse ein.',
      2: 'Klicken Sie auf den Link, den wir Ihnen per E-Mail senden.',
      3: 'Füllen Sie Ihre persönlichen Daten aus.',
      4: 'Wählen Sie die Höhe Ihrer Spende und das gewünschte Produkt.',
    },
    store_steps: {
      1: 'Geben Sie Ihre E-Mail-Adresse ein.',
      2: 'Klicken Sie auf den Link, den wir Ihnen per E-Mail senden.',
      3: 'Füllen Sie Ihre persönlichen Daten aus.',
      4: 'Wählen Sie "Neuen Laden anmelden".',
    },
    login: 'Weiter',
  },
  store_orders: {
    empty: 'Noch keine Bestellungen aufgegeben.',
    title: 'Bestellungen',
    reload: 'Aktualisieren',
  },
  store_shop: {
    steps: {
      1: 'Geben Sie Ihre E-Mail-Adresse ein.',
      2: 'Klicken Sie auf den Link, den wir Ihnen per E-Mail senden.',
      3: 'Bei Ihrer ersten Bestellung: Geben Sie Ihre persönlichen Daten ein.',
      4: 'Bei Ihrer ersten Bestellung: Fügen Sie eine neue Verkaufsstelle hinzu.',
      5: "Wählen Sie 'Neue Bestellung' bei Ihrer Verkaufsstelle.",
    },
  },
  stores: {
    empty: 'Noch keine Läden angemeldet.',
    name: 'Name des Ladens',
    remove: 'Laden entfernen',
    terms_approved: 'Zustimmung zu den Bedingungen',
  },
}

export default {
  errors: {
    people: {
      savePerson: {
        generic: 'We could not save your data.',
        noDonationAmount: 'You have entered an invalid donation amount.',
      },
    },
    orders: {
      createFailure: 'We could not create your order.',
      unknownUser:
        'You are trying to create an order for an unknown user. Please log in again and try again.',
    },
  },
  account: {
    add_store: 'Add Store',
    complete_registration:
      'Fill in your personal details to complete the registration.',
    log_out: 'Log Out',
    stores: 'Stores',
    welcome: 'Welcome',
  },
  address: {
    country: 'Country',
    postal_code: 'Postal Code',
    house_number: 'House Number',
    house_number_suffix: 'Unit',
    street: 'Street',
    locality: 'Locality',
  },
  donation_payment: {
    thanks: 'Thank you for submitting your donation.',
    do_first_payment:
      'You can now finalize your donation. If you want to receive the new flag at home first every year automatically, choose the annual donation with direct debit. We will collect from the account you are using now.',
    optionally_once:
      'If you do not want to authorize Marrekrite for automatic debit, choose: Donate once.',
    do_pay: 'Annual donation with direct debit',
    do_pay_once: 'Donate once',
    reload: 'Check Payment',
    already_paid: 'Already paid?',
  },
  donor_account: {
    payment_failed:
      'We could not generate a payment link for you. Please try again later.',
    paid_notice: 'We have successfully processed your donation.',
    set_donation: 'Set Donation',
    saved: 'Preferences saved',
    max_one_product:
      'You can choose a maximum of 1 product.<br>You can order additional items in the webshop.',
    product_explanation:
      'Choose the product you want to receive with your donation.',
    total_amount: 'Total amount: { amount }',
    donation_details:
      'You chose <strong>{ product }</strong>, with a donation of <strong>{ amount }</strong>.',
  },
  generic: {
    amount: 'Quantity',
    back: 'Back',
    confirm: 'Are you sure?',
    fail_message: 'Something went wrong.',
    log_in: 'Log In',
    minlength: 'Minimum { length } characters',
    or: 'or',
    remove: 'Remove',
    save: 'Save',
    total: 'Total',
  },
  home: {
    sign_up: 'Sign Up',
    account: 'Account',
    new_order: 'New Order',
  },
  login_form: {
    insert_digits: 'Email Address Verification',
    button: 'Log In',
    confirm_digits: 'Confirm Verification Code',
    invalid_digits: 'Incorrect Verification Code',
    digits: 'Verification Code',
    success: 'Please enter the 6-digit verification code we emailed you below.',
    terms:
      'By providing your email address, you agree to our <a href="https://marrekrite.frl/privacybeleid" class="underline" target="_blank">privacy policy</a>.',
  },
  new_person: {
    button: 'Sign Up',
  },
  new_store: {
    success: 'Store registered',
    title: 'Register Store',
    button: 'Register',
  },
  order_details: {
    product: 'Product',
  },
  order_form: {
    button: 'Order',
    personal_order: 'Personal Order',
    store_order: 'Business Order',
    no_lines: 'No products have been added yet.',
    add_line: 'Add Product',
    total: 'Total amount: {amount}',
  },
  people: {
    email: 'Email Address',
    first_name: 'First Name',
    last_name: 'Last Name',
    phone_number: 'Phone Number',
    min_donation_amount: 'The donation must be at least {amount}',
    donation_amount: 'Donation Amount',
    donation_product: 'Product',
    kinds: {
      donor: 'donor',
      store: 'store',
    },
  },
  shop: {
    back_to_account: 'Back to Your Account',
    button: 'Place Order',
    cart: 'Shopping Cart',
    check_again: 'Check Again',
    check_input: 'We could not process the form, please check the input.',
    complete_payments:
      'Before you can place a new order, you must first settle any existing orders.',
    edit_cart: 'Edit Shopping Cart',
    enter_personal_details:
      'Enter your personal information to complete the order. In the next step, you can review the order.',
    expect_shipping: 'Shipping costs may still apply.',
    log_in_first:
      'To place an order, we need your email address. Enter it below and click on the link we will send you.',
    next: 'Next',
    not_paid: 'We have not yet registered the payment ({ state }).',
    optional_donation: 'Optional Donation',
    paid: 'Your order has been successfully paid.',
    product_not_available: 'Not available',
    shipping_costs: 'Shipping Costs',
    shopping_for_store:
      'You are placing an order for <strong>{ name }</strong>',
    success: 'We have received your order in good order.',
    success_redirect:
      'We have received your order in good order, you will be redirected.',
    thanks: 'Thank you for your order',
    title: 'Webshop',
    total_amount: 'Total amount: { amount }',
  },
  sign_up: {
    title: 'Sign Up as',
    welcome:
      'Welcome to Recreatieschap Marrekrite, thank you for becoming a donor. To do so, follow the steps below.',
    steps: {
      1: 'Enter your email address.',
      2: 'Click on the link we email you.',
      3: 'Fill in your personal details.',
      4: 'Choose the amount of your donation and the desired product.',
    },
    store_steps: {
      1: 'Enter your email address.',
      2: 'Click on the link we email you.',
      3: 'Fill in your personal details.',
      4: 'Choose "Register New Store".',
    },
    login: 'Next',
  },
  store_orders: {
    empty: 'No orders placed yet.',
    title: 'Orders',
    reload: 'Refresh',
  },
  store_shop: {
    steps: {
      1: 'Enter your email address.',
      2: 'Click on the link that we email you.',
      3: 'For your first order: Enter your personal information.',
      4: 'For your first order: Add a new point of sale.',
      5: "Choose 'New order' at your point of sale.",
    },
  },
  stores: {
    empty: 'No stores registered yet.',
    name: 'Store Name',
    remove: 'Remove Store',
    terms_approved: 'Agree to terms',
  },
}
